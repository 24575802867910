export enum Role {
  Article = "article",
  Banner = "banner",
  ContentInfo = "contentinfo",
  Navigation = "navigation",
  Region = "region",
  Form = "form",
  Search = "search",
  Complementary = "complementary",
  Main = "main",
}
