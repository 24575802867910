export enum ContentTypes {
  Title = "Section Title with description",
  Button = "Button",
  Link = "Text link",
  Input = "Input field",
  Dropdown = "Select field",
  Image = "Image with description",
  // Checkbox = "List of check-boxes",
  // Radio = "List of radio-buttons",
  Bullets = "Bulleted list of text",
  BulletsLink = "Bulleted list of links",
  Numbers = "Numbered list of text",
  NumbersLink = "Numbered list of links",
  Paragraph = "Text paragraph",
  HeadingText = "Heading text",
  HeadingLink = "Heading link",
}
